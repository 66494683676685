import { mapState, mapGetters } from 'vuex'
import { MODULE_ARTICLE } from '@/model/ValueObject/UserPermissionModules'

export default {
  data () {
    return {
      reactWebs: {
        allowedSites: ['zena', 'magazin', 'eva', 'emma', 'polovnictvoarybarstvo', 'sarm',
          'lepsiebyvanie', 'wanda', 'plus1', 'plus7', 'sport24', 'ncpz', 'zivot', 'zdravie',
          'zahradkar', 'dobrejedlo', 'autobild'
        ]
      }
    }
  },
  computed: {
    ...mapState({
      articlePreviewUrl: state => state.config.articleSetting.articlePreviewUrl
    }),
    ...mapGetters(['production']),
    sites () {
      return this.$store.getters['site/enabledSites'](MODULE_ARTICLE)
    },
    articleSiteName () {
      const site = this.$store.getters['site/siteById'](this.article.site)
      if (site) {
        return site.name.replace(/_/g, '')
      }
      return ''
    }
  },
  methods: {
    getPreviewUrl () {
      const { previewHash } = this.article.setting
      const site = this.sites.find(site => site.id === this.article.site)

      if (
        !this.vlm &&
        site &&
        previewHash &&
        this.reactWebs.allowedSites.includes(site.name)
      ) {
        return `${site.domain}/preview/${previewHash}`
      }

      if (!this.vlm) {
        return `${this.articlePreviewUrl}/${this.article.id}`
      }

      if (this.production) {
        const siteIsInProductionMode = site.production
        let siteDomain
        if (siteIsInProductionMode) {
          siteDomain = site.domain
        } else {
          siteDomain = site.domain.slice()
          const insertedString = 'beta.'
          const searchedString = 'www.'

          if (siteDomain.includes(searchedString)) {
            siteDomain = siteDomain.replace(searchedString, insertedString)
          } else if (!siteDomain.includes(insertedString)) {
            const arr = siteDomain.split('//')
            siteDomain = `${arr[0]}//${insertedString}${arr[1]}`
          }
        }
        return `${siteDomain}/clanek-nahled/${previewHash}`
      }

      const previewUrl = this.articlePreviewUrl.replace('<site>', this.articleSiteName)
      return `${previewUrl}/${previewHash}`
    }
  }
}
